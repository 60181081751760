import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEineWohnungInDerSteiermark = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet eine Wohnung in der Steiermark?" showCalc={false} />
            <Article>
                <p>
                    Du bist im Moment auf der Suche nach einer Eigentumswohnung, vielleicht sogar deiner ersten? In
                    diesem Fall ist vor allem eines wichtig: dir möglichst früh einen Überblick über das
                    bundesländerspezifische Angebot zu verschaffen und dadurch ein Gespür für regionale Unterschiede auf
                    dem Markt zu entwickeln. In den Artikeln dieser Serie widmen wir uns daher dem Immobilienmärkten der
                    einzelnen Bundesländer und geben dir einen ersten Überblick über das dortige Angebot. Diesmal nehmen
                    wir uns die Steiermark vor.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Der Immobilienmarkt in Österreich: aktuelle Entwicklungen</h2>
                <p>
                    Um die jüngsten Entwicklungen auf dem Markt im Burgenland einordnen zu können, müssen wir sie im
                    Kontext der Preisentwicklung in ganz Österreich betrachten. Folgende Tendenzen waren 2021 zu
                    beobachten. Sie sind auf die seit längerem besonders günstige Fixzinsbindung bei Krediten
                    zurückzuführen:
                </p>
                <ul>
                    <li>
                        Der Anstieg der Immobilienpreise lag im zweiten Quartal nach wie vor im zweistelligen
                        Prozentbereich und betrug 11,7 %.
                    </li>
                    <li>
                        Der Preisanstieg bei Einfamilienhäusern außerhalb Wiens nahm gegenüber dem ersten Quartal (12,
                        9%) ab und betrug 11,3 %.
                    </li>
                    <li>
                        Der Preisanstieg ist außerhalb Wiens höher als innerhalb: In der Bundeshauptstadt betrug er 2021
                        im ersten Quartal 10,9 % und im zweiten 10,7 %. In den übrigen Bundesländern war ein Anstieg von
                        14,8 % bzw. 12,8 % zu beobachten.
                    </li>
                    <li>
                        Umgelegt auf den Markt an neuen Eigentumswohnungen: Hier stiegen die in Wien 2021 um 10,4 % im
                        ersten und um 10,1 % im zweiten Quartal. Außerhalb der Hauptstadt kam es dagegen hingegen zu
                        einer Teuerung von 16,7 % bzw. 12,9 %.
                    </li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in der Steiermark</h2>
                <p>
                    Der Immobilienmarkt in der Steiermark figuriert in Fachkreis auch als “Steiermarkt”. Aber Spaß und
                    schlechte Wortwitze einmal beiseite: Was den steirischen Markt tatsächlich auszeichnet, ist ein
                    ausgeprägtes Stadt-Land-Gefälle. Für eine Eigentumswohnung in Graz zahlst du etwa doppelt so viel
                    wie für eine Wohnung in Murau. Interessant ist auch der erhebliche Preisunterschied zwischen neuen
                    und gebrauchten Wohnungen: Erstere sind meist 60 bis 90 % teurer.
                </p>
                <hr />

                <h2>Das kosten Wohnungen in der Steiermark</h2>
                <p>
                    Beginnen wir, wie in dieser Serie üblich, mit der Hauptstadt: Im urbanen Gebiet von Graz kostet dich
                    eine Eigentumswohnung derzeit 3 093,99 € pro Quadratmeter Wohnfläche. Wirft man einen Blick auf die
                    Preise in Weiz, so wird das beschriebene Stadt-Land-Gefälle augenfällig: Mit 2 103,50 € liegen die
                    Kosten für einen Quadratmeter hier deutlich unter denen der Hauptstadt. Noch ein wenig günstiger
                    fallen sie in Leibnitz aus, wo du aktuell mit einem Quadratmeterpreis von 2 036,00 € rechnen musst.
                    Wiederum um einiges günstiger ist der Wohnraum momentan im für seine Brauerei bekannten Murau: Deine
                    Traumwohnung bekommst du dort für 1 577 € pro Quadratmeter. Du willst es noch billiger? Kein
                    Problem: In Loeben rangiert der Quadratmeterpreis für Eigentumswohnungen derzeit bei 1 516,25 €.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEineWohnungInDerSteiermark"}></BreadcrumbList>
            <ArticleStructuredData
                page={"wasKostetEineWohnungInDerSteiermark"}
                heading={"Was kostet eine Wohnung in der Steiermark?"}
            />
        </Layout>
    );
};

export default WasKostetEineWohnungInDerSteiermark;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: ["header", "footer", "page.artikel.was-kostet-eine-wohnung-in-der-steiermark", "mortgageCalc"]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
